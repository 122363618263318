import React from 'react';
import Navbar from './Navbar';
import Contact from './Contact';
import './Home.css'; // Import CSS file for additional styles
import Copyright from './Copyright';
import Services from './Services';
// import backImage from './Images/backImage.png'
import backImage from './Images/Data-Center.jpg'
import About from './About';
import Products from './Products';
import NavNumber from './NavNumber';
import GooogleMap from './GoogleMap';

function Home() {
  const handleCallUs = () => {
    window.location.href = 'tel:+971504842940'; // Replace with your phone number
  };
  
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, -0.3)', // Dark overlay
    zIndex: -1
  }; 

  const textStyle = {
    position: 'absolute',
    top: '20px', // Position 20px above the top of the background image
    left: '50%',
    transform: 'translateX(-50%)', // Center horizontally
    textAlign: 'center',
    width: '80%', // Adjust as needed
    color: 'white', // Text color
    zIndex: 1 // Ensure the text appears above the background image overlay
  };

  return (
    <div id="home" className="home-container">
      <NavNumber/>
      <Navbar />
      <div className="background-container m-3 p-3">
        <div style={textStyle}>
          <h4 className='text-center mt-5'><b>Welcome to<h1> Noor Masafi IT Network Service</h1></b></h4>
          <p className='text-center'>We provide high-quality IT services.</p>
        </div>
        <div className='mt-0'></div>

        <button className="btn btn-primary btn-lg call-us-button" onClick={handleCallUs}>Call Us</button>
        <img src={backImage} alt="Repair Shop" className="background-image" />
      </div>
      <div style={overlayStyle}></div>
      <About/>
      <Services/>
      <Products/>
      <GooogleMap/>
      <Contact/>
      <Copyright/>
    </div>
  );
}

export default Home;
