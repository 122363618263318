import React from 'react';
import './Copyright.css'

function Copyright() {
  return (
    <div className="copyright-container">
      <p>&copy; 2024 Noor Masafi IT Network Services Co. All rights reserved.</p>
    </div>
  );
}

export default Copyright;
