import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './Images/logo.png';
import Navbar from './Navbar';

import image1 from './Images/Image1.jpg'
import image2 from './Images/Image2.jpeg'
import image3 from './Images/image3.jpeg'
import image4 from './Images/image4.jpeg'
import story1 from './Images/story1.jpeg'
import story2 from './Images/story2.jpeg'
import story3 from './Images/story3.jpeg'
import story4 from './Images/story4.jpeg'
import story5 from './Images/story5.jpeg'
import story6 from './Images/story6.jpeg'
import story7 from './Images/story7.jpeg'
import story8 from './Images/picdb.jpeg'
import story9 from './Images/racks.jpeg'


// import Navbar from './Navbar';
import Copyright from './Copyright';
import Contact from './Contact'


// import NavbarImages from './NavbarImages';



// Import other couch images as needed

const Gallery = () => {
    const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="couches-page">
      {/* <NavbarImages/> */}
      <Navbar/>
      <div className='p-2'>
        <h1 className='text-center'>Our Gallary</h1>
      </div>

      {/* Image grid */}
      <div className="image-grid" style={imageGridStyle}>
        {/* Add 10 different couch images */}
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story1} alt="Couch 1" style={couchImageStyle}/>
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story2} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story3} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story4} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story5} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story6} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story7} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story8} alt="Couch 1" style={couchImageStyle} />
        <img className={`card h-100 ${isVisible ? 'animate__slideInDown' : ''}`} src={story9} alt="Couch 1" style={couchImageStyle} />
        
      </div>
      <Contact/>
      <Copyright/>
    </div>
  );
}

// Styles
const navbarStyle = {
  backgroundColor: '#fff',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow for depth
  padding: '20px 0',
};

const logoStyle = {
  width: '40px',
  marginRight: '10px',
  float: 'left', // Align the logo to the left
};

const titleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  margin: '0', // Remove margin for a cleaner look
};

const imageGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', // Responsive grid
  gap: '20px',
  padding: '20px',
};

const couchImageStyle = {
  width: '100%',
  height: 'auto',
  borderRadius: '10px', // Increased border radius for a softer look
};


export default Gallery;
