import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar from './Navbar';
import Contact from './Contact';
import Home from './Home';
import About from './About.js';
import Services from './Services';
import WhatsAppFunc from './Whatsapp';
import NavNumber from './NavNumber';
import Gallery from './Gallery';
import Offering from './Offering';
import Login from './Office/Login'
import GoogleMap from './GoogleMap';
import './App.css';
import Products from './Products';
import Test from './Office/test';
import Register from './Office/RegisterOffice';
import StoreMaterials from './Office/StoreMaterial';
import StoreItems from './Office/StoredItems';
import DevicesLog from './Office/DevicesLog';
import ShowDevices from './Office/ShowDevices';

function App() {
  return (
    <BrowserRouter>
    <div>
    
      <div>
      <WhatsAppFunc/>
        <Routes>
          <Route path='/navbar' element={<Navbar/>}></Route>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/whatsapp' element={<WhatsAppFunc/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/navNumber' element={<NavNumber/>}></Route>
          <Route path='/gallery' element={<Gallery/>}></Route>
          <Route path='/products' element={<Products/>}></Route>
          <Route path='/gMap' element={<GoogleMap/>}></Route>
        </Routes>
      </div>
    </div>
      <Routes>
        {/* <Route path='/' element={<Login/>}></Route> */}
        <Route path='/register' element={<Register/>}/>
        <Route path='/storeMaterials' element={<StoreMaterials/>}/>
        <Route path='/storeItems' element={<StoreItems/>}/>
        <Route path='/devicesLog' element={<DevicesLog/>}/>
        <Route path='/showDevices' element={<ShowDevices/>}/>
        {/* <Route path='/' element={<Test/>}/> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
