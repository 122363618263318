import React, { useState } from 'react';
// import './StoreMaterial.css'
import axios from 'axios';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBIcon,
    MDBCheckbox
} from 'mdb-react-ui-kit';

function DevicesLog() {
    // State variables
    const [description, setDescription] = useState('');
    const [takenBy, setTaken] = useState('');
    const [takenDate, setDate] = useState('');


    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const devices = {
            description,
            takenBy,
            takenDate
        };

        axios.post('http://localhost:5001/api/devices', devices)
            .then((res) => {
                console.log(res.data);
                setDescription('');
                setTaken('')
                setDate('');
                alert('Data Stored Successfully');
            })
            .catch((error) => {
                console.error('Error saving Devices', error);
            });
    };

    return (
        <div className="store-materials-container d-flex justify-content-center align-items-center">
            <div className="card custom-card">
                <h2 className="m-4">Input Devices Log</h2>
                <form onSubmit={handleSubmit}>
                    {/* Name input row */}
                    <div className="row mb-3">
                        <label htmlFor="name" className="col-sm-4 col-form-label">Description</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="description" 
                            value={description} onChange={(e)=>setDescription(e.target.value)} required />
                        </div>
                    </div>

                    {/* Description input row */}
                    <div className="row mb-3">
                        <label htmlFor="Name" className="col-sm-4 col-form-label">TakenBy</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="takenBy"
                            value={takenBy} onChange={(e)=>setTaken(e.target.value)} required />
                        </div>
                    </div>

                    {/* Quantity input row */}
                    <div className="row mb-3">
                        <label htmlFor="quantity" className="col-sm-4 col-form-label">Date</label>
                        <div className="col-sm-8">
                            <input type="date" className="form-control" id="date"
                            value={takenDate} onChange={(e)=>setDate(e.target.value)} required />
                        </div>
                    </div>

                    {/* Submit button */}
                    <div className="d-flex justify-content-center">
                        <button className='btn btn-primary' type="submit" color="primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DevicesLog;
