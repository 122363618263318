import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StoreItems from "./StoredItems";

function Register(){
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [changePass,setChangePass] = useState('');


    const navigate = useNavigate();
    const loginHandle= ()=>{
        navigate('/');
    }
    const signupHandle= ()=>{
      const input ={
        name,
        email,
        password
      };
      axios.post('http://localhost:5001/register',input)
      .then((res)=>{
        setName('');
        setEmail('')
        setPassword('');
        alert("Registration Successfull")
      })
      .catch((err)=>{
        console.error("error is registration",err)
        alert("Unsessfull registranio",err)
      })
      navigate('/showDevices');
  }

    return(
        <div className="back d-flex justify-content-center align-items-center">
      <div className="card custom-card"> {/* Added custom-card class */}
        <h2 className="m-4">Registration</h2>
        <form>
        <div className="form-outline mb-4">
            <input type="text" id="form2Example1" value={name}
            onChange={(e)=>setName(e.target.value)} className="form-control" />
            <label className="form-label" htmlFor="form2Example1">Name</label>
          </div>
          {/* Email input */}
          <div className="form-outline mb-4">
            <input type="email" id="form2Example1" 
            value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
            <label className="form-label" htmlFor="form2Example1">Email address</label>
          </div>

          {/* Password input */}
          <div className="form-outline mb-4">
            <input type="password" id="form2Example2"
            value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" />
            <label className="form-label" htmlFor="form2Example2">Password</label>
          </div>
          <div className="form-outline mb-4">
            <input type="password" id="form2Example2"
            value={changePass} onChange={(e)=>setChangePass(e.target.value)} className="form-control" />
            <label className="form-label" htmlFor="form2Example2">Confirm Password</label>
          </div>

          {/* 2 column grid layout for inline styling */}
          <div className="row mb-4">
            <div className="col">
            </div>
          </div>

          {/* Submit button */}
          <button type="button" className="btn btn-primary btn-block mb-4" onClick={signupHandle}>Sign up</button>

          {/* Register buttons */}
          <div className="text-center">
            <p>Already Have an Account? <a href="#!" onClick={loginHandle}>Login</a></p>
            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-facebook-f"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-google"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-twitter"></i>
            </button>

            <button type="button" className="btn btn-link btn-floating mx-1">
              <i className="fab fa-github"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
    )
}

export default Register;