import React, { useState } from 'react';
import './StoreMaterial.css'
import axios from 'axios';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBIcon,
    MDBCheckbox
} from 'mdb-react-ui-kit';

function StoreMaterials() {
    // State variables
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const material = {
            name,
            description,
            quantity
        };

        axios.post('http://localhost:5001/api/materials', material)
            .then((res) => {
                console.log(res.data);
                setName('');
                setDescription('');
                setQuantity('');
                alert('Data Stored Successfully');
            })
            .catch((error) => {
                console.error('Error saving material', error);
            });
    };

    return (
        <div className="store-materials-container d-flex justify-content-center align-items-center">
            <div className="card custom-card">
                <h2 className="m-4">Input Materials</h2>
                <form onSubmit={handleSubmit}>
                    {/* Name input row */}
                    <div className="row mb-3">
                        <label htmlFor="name" className="col-sm-4 col-form-label">Name</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="name" 
                            value={name} onChange={(e)=>setName(e.target.value)} required />
                        </div>
                    </div>

                    {/* Description input row */}
                    <div className="row mb-3">
                        <label htmlFor="description" className="col-sm-4 col-form-label">Description</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control" id="description"
                            value={description} onChange={(e)=>setDescription(e.target.value)} required />
                        </div>
                    </div>

                    {/* Quantity input row */}
                    <div className="row mb-3">
                        <label htmlFor="quantity" className="col-sm-4 col-form-label">Quantity</label>
                        <div className="col-sm-8">
                            <input type="number" className="form-control" id="quantity"
                            value={quantity} onChange={(e)=>setQuantity(e.target.value)} required />
                        </div>
                    </div>

                    {/* Submit button */}
                    <div className="d-flex justify-content-center">
                        <button className='btn btn-primary' type="submit" color="primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default StoreMaterials;
