import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import './Product.css'; // Import your custom CSS file for styling
import image1 from './Images/Image1.jpg';
import image2 from './Images/Image2.jpeg';
import image3 from './Images/image3.jpeg';
import image5 from './Images/fingerprint.jpeg';
import tele from './Images/telephone.jpeg';
import image10 from './Images/cctv.jpeg';
import image11 from './Images/cable.jpeg';
import image12 from './Images/doorlock.jpeg';
import image13 from './Images/mdf2.jpeg';
import image15 from './Images/phanasonic2.jpeg';
import image16 from './Images/unifi.jpeg';

const images = [image1, image2, image3, image5, tele, image10, image11, image12, image13, image15, image16]; // Add more images as needed

function Products() {
  const [index, setIndex] = useState(0);

  // Automatic carousel index update every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id="products" className="products-section py-5"> {/* Apply the custom class */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Carousel
              activeIndex={index}
              onSelect={(selectedIndex) => setIndex(selectedIndex)}
              interval={4000}
            >
              {images.map((image, idx) => (
                <Carousel.Item key={idx}>
                  <div className="row">
                    <div className="col-md-4">
                      <img 
                        src={images[(idx * 3) % images.length]} 
                        className="d-block w-100 image-rounded" // Apply the custom class for rounded corners
                        alt={`Product ${idx * 3 + 1}`} 
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} 
                      />
                    </div>
                    <div className="col-md-4">
                      <img 
                        src={images[(idx * 3 + 1) % images.length]} 
                        className="d-block w-100 image-rounded" // Apply the custom class for rounded corners
                        alt={`Product ${idx * 3 + 2}`} 
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} 
                      />
                    </div>
                    <div className="col-md-4">
                      <img 
                        src={images[(idx * 3 + 2) % images.length]} 
                        className="d-block w-100 image-rounded" // Apply the custom class for rounded corners
                        alt={`Product ${idx * 3 + 3}`} 
                        style={{ width: '100%', height: '400px', objectFit: 'cover' }} 
                      />
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
