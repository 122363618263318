import React from 'react';
import { FaPhone } from 'react-icons/fa'; // Import the phone icon

function NavNumber() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <ul className="navbar-nav ml-auto"> {/* Use ml-auto class to align items to the right */}
          <li className="nav-item">
            <a className="nav-link1" href="tel:+971504842940" style={{ height: '30px' }}> {/* Adjust height here */}
              <FaPhone style={{ marginBottom: '2px' }} /> {/* Adjust margin to center the icon vertically */}
              <span className="ml-2" style={{ fontSize: '0.9rem' }}>+971504842940</span> {/* Adjust font size and add margin */}
            </a>
          </li>
        </ul>
      </div>

      <style jsx>{`
        .navbar {
          background-color: rgb(49, 77, 77);
          color: #fff;
          border-bottom: 1px solid rgba(255, 255, 255, 0.7); /* Change border color if needed */
          padding: 0 0;
        }

        .nav-link1 {
          color: white;
        }

        .navbar-brand {
          font-weight: bold;
          font-size: 1.2rem;
          color: white; /* Change navbar-brand color if needed */
        }

        .navbar-brand:hover {
          color: #ffc107; /* Change color on hover */
        }

        .logo-text {
          vertical-align: middle;
          margin-left: 10px; /* Add margin to separate logo from text */
        }

        .navbar-nav .nav-link {
          color: black; /* Change nav-link color if needed */
          font-weight: 500;
          height: auto; /* Reset height */
        }

        .navbar-nav .nav-link:hover {
          color: #ffc107; /* Change color on hover */
        }

        .navbar-toggler {
          border: none;
          outline: none;
          color: gray; /* Change navbar-toggler color to gray */
        }

        .navbar-toggler:hover {
          color: #ffc107; /* Change color on hover */
        }
      `}</style>
    </nav>
  );
}

export default NavNumber;
