import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBIcon,
    MDBCheckbox,
} from 'mdb-react-ui-kit';

function StoreItems() {
    // State variables
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [materials, setMaterials] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const material = {
            name,
            description,
            quantity,
        };

        axios.post('http://localhost:5001/api/materials', material)
            .then((res) => {
                console.log(res.data);
                setName('');
                setDescription('');
                setQuantity('');
                alert('Data Stored Successfully');
                // Fetch the updated list of materials after adding a new one
                fetchMaterials();
            })
            .catch((error) => {
                console.error('Error saving material', error);
            });
    };

    // Fetch all saved materials from the server
    const fetchMaterials = () => {
        axios.get('http://localhost:5001/api/materials')
            .then((res) => {
                setMaterials(res.data);
                console.log(res.data)
            })
            .catch((error) => {
                console.error('Error fetching materials', error);
            });
    };

    // Fetch materials on component mount
    useEffect(() => {
        fetchMaterials();
    }, []);

    // Filter materials based on search query
    const filteredMaterials = materials.filter(material => 
        material.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <MDBContainer fluid>
            <div>
                    {/* Search bar */}
                    <div className="mt-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by name..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {/* Display filtered materials */}
                    <div className="mt-4">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredMaterials.map(material => (
                                    <tr key={material._id}>
                                        <td>{material.name}</td>
                                        <td>{material.description}</td>
                                        <td>{material.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
        </MDBContainer>
    );
}

export default StoreItems;
