import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './About.css'; // Import custom CSS for styling
import image1 from './Images/Image1.jpg';
import image2 from './Images/Image2.jpeg';
import image3 from './Images/image3.jpeg';
import image4 from './Images/image4.jpeg';
import story from './Images/story.jpeg'

function About() {
  // State to manage whether the gallery is visible
  const [showGallery, setShowGallery] = useState(false);

  // Function to toggle the gallery visibility
  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };

  return (
    <section
      id="about"
      className="py-5 for-color" // Add the custom CSS class for the background color
      style={{ minHeight: '95vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} // Flexbox layout to position the button at the bottom middle
    >
      <div className='for-color'>
        <div className="about-container shadow-lg"> {/* Apply custom container class and set background color to dark gray */}
          <h2 className="mb-4 text-center">About Us</h2>
          <div className="row p-4">
            {/* First row */}
            <div className="col-md-6 col-lg-4 mb-4">
              <div className='card-img-top'>
                <div className="card-body">
                  <h5 className="card-title shadow-lg">Noor Masafi IT Network Solution</h5>
                  <p className="all card-text ">
                  Noor Masafi Computers is a leading IT and Telecom solutions provider based in Dubai, UAE. 
                  We provide superior Consulting and solutions for telephonic systems,  
                  IT, and infrastructure services. Our goals are to exceed the expectations of every client by 
                  offering outstanding customer services, Increased supports, and greater values. 
                  We offer Quality Services in the area of IT infrastructure and Web design. 
                  </p>
                </div>
              </div>
            </div>
            {/* Remaining code for image display */}
            <div className="col-md-6 col-lg-4 mb-4 border-radius">
              <div className="all card-body shadow">
                <img src={story} className="image card-img-top" alt="Image 1" style={{ height: '300px' }} /> {/* Add fixed height style */}
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="all card-body shadow border-radius">
                <img src={image1} className="image card-img-top" alt="Image 2" style={{ height: '300px' }} /> {/* Add fixed height style */}
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '0px' }}>
          {/* <Link to="/gallery" onClick={toggleGallery}>See all</Link> */}
          <Link to="/gallery" className="btn btn-primary" onClick={toggleGallery}>Photo Gallery</Link>
        </div>
        </div>
      </div>
    </section>
  );
}

export default About;
