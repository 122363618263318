import React from 'react';
import './Services.css'; // Import the CSS file

function Services() {
  return (
    <div id='services' className="services-container">
      <h2 className="services-title text-center mb-4">Our Services</h2>
      <div className="services-cards row">
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title text-center"><b>Structured Solutions</b></h3>
          <p>We specialize in creating reliable structured cabling systems for IT networks. 
            Our solutions ensure smooth connections and fast data transfer. 
            Using the latest technology, we design and install cabling tailored to your needs. 
            Whether it's for offices, data centers, or industrial sites, our team delivers 
            scalable and future-proof solutions. From start to finish, we provide comprehensive 
            services to keep your network running smoothly. Trust us for simple, effective cabling 
            solutions that support your business needs. 
            </p>
            </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>UTP Data Cable</b></h3>
          <p>We specialize in UTP (Unshielded Twisted Pair) data cables for IT networks. 
            UTP cables are vital for transmitting data smoothly within networks. 
            Our high-quality UTP cables meet industry standards, ensuring top performance. 
            They're durable, flexible, and support fast data transfer. Whether it's for small 
            offices or big companies, our UTP cables are perfect for voice, data, and video 
            transmission. Trust us to provide dependable UTP cables that keep your IT systems 
            connected seamlessly.
             </p>
            </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>CCTV Solutions</b></h3>
          <p>At Noor Masafi IT Network Service, we specialize in providing top-notch CCTV 
            services to ensure the safety and security of your home or business. 
            Our skilled team designs and installs customized surveillance systems tailored 
            to your unique needs. We offer high-definition cameras, advanced monitoring solutions, 
            and seamless integration with other security systems for comprehensive protection. 
            Trust us to safeguard your property with cutting-edge technology and 
            unparalleled customer service.
            </p>
              </div>
        <div className="card col-md-6 col-lg-3 mb-4">
          <h3 className="card-title"><b>Access Control Solutions</b></h3>
          <p>Noor Masafi offers  state-of-the-art access control solutions to enhance the 
            security of your premises. Our comprehensive range of services includes the installation, 
            maintenance, and support of modern access control systems tailored to your specific needs. 
            Whether you require biometric, card-based, or keypad access, our team ensures seamless  
            integration with your existing infrastructure. With our reliable solutions, you can 
            monitor and manage entry points efficiently, providing a secure environment for your 
            business. 
            </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
