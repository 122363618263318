import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBInput,
    MDBIcon,
    MDBCheckbox,
} from 'mdb-react-ui-kit';

function ShowDevices() {
    // State variables
    const [description, setDescription] = useState('');
    const [takenBy, setTakenBy] = useState('');
    const [takenDate, setTakenDate] = useState('');
    const [materials, setMaterials] = useState([]);

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        const devices = {
            description,
            takenBy,
            takenDate,
        };

        axios.post('http://localhost:5001/api/devices', devices)
            .then((res) => {
                console.log(res.data);
                setDescription('');
                setTakenBy('');
                setTakenDate('');
                alert('Data Stored Successfully');
                // Fetch the updated list of materials after adding a new one
                fetchMaterials();
            })
            .catch((error) => {
                console.error('Error saving material', error);
            });
    };

    // Fetch all saved materials from the server
    const fetchMaterials = () => {
        axios.get('http://localhost:5001/api/devices')
            .then((res) => {
                setMaterials(res.data);
                console.log(res.data);
            })
            .catch((error) => {
                console.error('Error fetching materials', error);
            });
    };

    // Fetch materials on component mount
    useEffect(() => {
        fetchMaterials();
    }, []);

    return (
        <MDBContainer fluid>
            <div>
                {/* Display materials */}
                <div className="mt-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Taken By</th>
                                <th>Taken Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materials.map(material => (
                                <tr key={material._id}>
                                    <td>{material.description}</td>
                                    <td>{material.takenBy}</td>
                                    <td>{material.takenDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </MDBContainer>
    );
}

export default ShowDevices;
