import React from 'react';
import WhatsAppIcon from './Images/WhatsApp_icon.png';
import { FaWhatsapp } from 'react-icons/fa';
// import wp from './images/WhatsApp_icon.png'

function WhatsAppFunc() {
  // Replace the 'your-phone-number' with your actual WhatsApp phone number
  const whatsappLink = `https://wa.me/+971504842940`;

  return (
    <div style={styles.container}>
      <h3 className='text-center'>Reach Us</h3>
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={styles.link}>
        <FaWhatsapp size={40} />
      </a>
    </div>
  );
}

const styles = {
  container: {
    position: 'fixed',
    bottom: '80px',
    right: '50px',
    zIndex: '1000', // Adjust as needed to ensure the icon is above other elements
  },
  link: {
    textDecoration: 'none',
    color: '#25D366', // WhatsApp brand color
  },
};

export default WhatsAppFunc;