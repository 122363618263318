import React from "react";
import GoogleMapReact from 'google-map-react';
import "./GoogleMap.css"

function GooogleMap(){
    return(
        <div className="clr">
            <h2 className="clr">Address</h2>
        <div className="responsive-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3607.9393381202526!2d55.313623!3d25.272626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDE2JzIxLjUiTiA1NcKwMTgnNDkuMCJF!5e0!3m2!1sen!2sae!4v1713011701109!5m2!1sen!2sae" 
        width="600" height="450" style={{border:"0"}} allowfullscreen="" 
        loading="lazy" referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        </div>
    </div>
    )
}
export default GooogleMap;